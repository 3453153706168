import { run, Params, YearStocks, YearFlows } from "drawdown-web";
import "chart.js/auto";
import { Chart, Bar } from "react-chartjs-2";

// ["rgb(%i, %i, %i)"%(r*256, g*256, b*256) for (r,g,b) in sns.color_palette()]
const cmap = [
  "rgb(31, 119, 180)",
  "rgb(256, 127, 14)",
  "rgb(44, 160, 44)",
  "rgb(214, 39, 40)",
  "rgb(148, 103, 189)",
  "rgb(140, 86, 75)",
  "rgb(227, 119, 194)",
  "rgb(127, 127, 127)",
  "rgb(188, 189, 34)",
  "rgb(23, 190, 207)",
];

const stocks_colors = {
  NormalDeposits: cmap[0],
  NormalGains: cmap[1],
  RothContrib: cmap[2],
  RothUnvested: cmap[3],
  RothEarn: cmap[4],
  Trad: cmap[5],
} as const;

const flows_colors = {
  SpentDeposits: cmap[0],
  SpentGains: cmap[1],
  SpentRothContrib: cmap[2],
  SpentRothEarn: cmap[4],
  SpentTrad: cmap[5],
  Rollover: cmap[6],
  Tax: cmap[7],
} as const;

const stocks_full_names = {
  NormalDeposits: "normal basis",
  NormalGains: "normal unrealized gains",
  RothContrib: "roth contributions",
  RothUnvested: "unvested roth conversions",
  RothEarn: "roth earnings",
  Trad: "traditional IRA/401(k)",
} as const;

function Simulation(props: Params) {
  console.log(props);
  const { stocks, flows, constraints, fpl, aca_cost } = run(props);
  console.log(constraints);
  const combined_stocks = [props.initial_stocks, ...stocks];
  return (
    <div className="Simulation">
      <StocksChart stocks={combined_stocks} start_age={props.start_age} />
      <SpendingChart flows={flows} start_age={props.start_age} />
      <TaxableIncomeChart flows={flows} start_age={props.start_age} fpl={fpl} />
      <details>
        <summary>Stocks</summary>
        <StocksTable stocks={combined_stocks} />
      </details>
      <details>
        <summary>Flows</summary>
        <FlowsTable flows={flows} aca_cost={aca_cost} />
      </details>
    </div>
  );
}

function StocksChart({
  stocks,
  start_age,
}: {
  stocks: YearStocks[];
  start_age: number;
}) {
  const labels = stocks.map((_, i) => i + start_age);
  const stocks_types = [
    "NormalDeposits",
    "NormalGains",
    "RothContrib",
    "RothUnvested",
    "RothEarn",
    "Trad",
  ] as const;
  const datasets = stocks_types.map((ty) => {
    return {
      label: ty,
      data: stocks.map((yr_stocks) => yr_stocks[ty]),
      backgroundColor: stocks_colors[ty],
    };
  });
  const data = { labels, datasets };
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Stocks",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return <Bar data={data} options={options} />;
}

function StocksTable({ stocks }: { stocks: YearStocks[] }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const stocks_rows = stocks.map((row, i) => {
    const row_tds = [
      row.NormalDeposits,
      row.NormalGains,
      row.RothContrib,
      row.RothUnvested,
      row.RothEarn,
      row.Trad,
    ].map((x, i) => <td key={i}>{formatter.format(x || 0)}</td>);
    return (
      <tr key={i}>
        <td>{i}</td>
        {row_tds}
      </tr>
    );
  });
  return (
    <table>
      <thead>
        <tr>
          <td>Year</td>
          <td>Normal Deposits</td>
          <td>Normal Gains </td>
          <td>Roth Contrib </td>
          <td>Roth Unvested </td>
          <td>Roth Earn </td>
          <td>Trad </td>
        </tr>
      </thead>
      <tbody>{stocks_rows}</tbody>
    </table>
  );
}

function SpendingChart({
  flows,
  start_age,
}: {
  flows: YearFlows[];
  start_age: number;
}) {
  const labels = flows.map((_, i) => i + start_age);
  const flows_types = [
    "SpentTrad",
    "SpentGains",
    "SpentDeposits",
    "SpentRothContrib",
    "SpentRothEarn",
  ] as const;
  const datasets = flows_types.map((ty) => {
    return {
      label: ty.split("Spent").pop(),
      data: flows.map((yr_flows) => yr_flows[ty]),
      backgroundColor: flows_colors[ty],
    };
  });
  const data = { labels, datasets };
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Spending",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return <Bar data={data} options={options} />;
}

function TaxableIncomeChart({
  flows,
  start_age,
  fpl,
}: {
  flows: YearFlows[];
  start_age: number;
  fpl: number[];
}) {
  const labels = flows.map((_, i) => i + start_age);
  const flows_types = ["Rollover", "SpentTrad", "SpentGains"] as const;
  const flows_datasets = flows_types.map((ty) => {
    return {
      type: "bar" as const,
      label: ty.split("Spent").pop(),
      data: flows.map((yr_flows) => yr_flows[ty]),
      backgroundColor: flows_colors[ty],
      pointStyle: "rect",
    };
  });
  const fpl_dataset = {
    type: "line" as const,
    label: "medicaid cutoff",
    data: fpl.map((x) => x * 1.38),
    pointStyle: "line",
    borderColor: "rgb(0,0,0)",
  };
  const datasets = [fpl_dataset, ...flows_datasets];
  const data = { labels, datasets };
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Taxable Income",
      },
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  //@ts-ignore
  return <Chart data={data} options={options} />;
}

function FlowsTable(props: { flows: YearFlows[]; aca_cost: number[] }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const flows_rows = props.flows.map((row, i) => {
    const row_tds = [
      row.SpentDeposits,
      row.SpentGains,
      row.SpentRothContrib,
      row.SpentRothEarn,
      row.SpentTrad,
      row.Rollover,
      row.Tax,
      props.aca_cost[i],
    ].map((x, i) => <td key={i}>{formatter.format(x || 0)}</td>);
    return (
      <tr key={i}>
        <td>{i + 1}</td>
        {row_tds}
      </tr>
    );
  });
  return (
    <table>
      <thead>
        <tr>
          <td className="noCell"></td>
          <td colSpan={5}>Spending</td>
        </tr>
        <tr>
          <td>Year</td>
          <td>Deposits</td>
          <td>Gains</td>
          <td>RothContrib</td>
          <td>RothEarn</td>
          <td>Trad</td>
          <td>Rollover</td>
          <td>Tax</td>
          <td>ACA</td>
        </tr>
      </thead>
      <tbody>{flows_rows}</tbody>
    </table>
  );
}

export default Simulation;
