import { useState } from "react";
import { Params } from "drawdown-web";
import Simulation from "./Simulation";
import SimulationInput, { status_table } from "./Input";
import "./App.css";
import { simToInputParams } from "./schemas";

function App() {
  const [params, setParams] = useState<Params>({
    n_years: 45,
    n_adults: 2,
    child_births: [-16, -14, -12],
    start_age: 45,
    initial_stocks: {
      NormalDeposits: 300000,
      NormalGains: 375000,
      RothContrib: 225000,
      RothUnvested: 0,
      RothEarn: 150000,
      Trad: 650000,
    },
    spending: [
      80000, 120000, 120000, 165000, 165000, 155000, 155000, 105000, 105000,
      55000, 55000, 55000, 55000, 30000, 30000, 30000, 30000, 30000, 30000,
      35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000,
      35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000, 35000,
      35000, 35000, 35000, 35000, 35000, 35000,
    ],
    ira_eligible_spending: [
      0.0, 50000, 50000, 100000, 100000, 100000, 100000, 50000, 50000, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0,
    ],
    interest_rate: 0.03,
    fpl_table: [
      13590.0, 18310.0, 23030.0, 27750.0, 32470.0, 37190.0, 41910.0, 46630.0,
    ],
    minor_child_tax_credit_nonrefundible: 600.0,
    adult_child_tax_credit_nonrefundible: 500.0,
    ...status_table.mfj,
  });
  return (
    <div className="App">
      <SimulationInput
        setParams={setParams}
        params={simToInputParams(params)}
      />
      <Simulation {...params} />
    </div>
  );
}

export default App;
